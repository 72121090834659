import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
// import useOutsideClick from '../../utils/useOutsideClick';

const CloseIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_6869_28759)'>
      <path
        d='M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z'
        fill='#1E1E1F'
      />
    </g>
    <defs>
      <clipPath id='clip0_6869_28759'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const HeaderMenu = ({ isOpen, setIsOpen }) => {
  const { push } = useHistory();
  const ref = useRef();

  //   useOutsideClick(ref, () => isOpen && setIsOpen(false));

  const handleClick = (path) => {
    push(path);
    setIsOpen(false);
  };

  return (
    <div
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      className={`${
        isOpen ? 'd-block' : 'd-none'
      } vh-100 w-75 bg-white position-absolute top-0 left-0`}
      style={{ zIndex: '7' }}
    >
      <div className='border-b border' style={{ padding: '1rem' }}>
        <div onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </div>
      </div>
      <div
        style={{ padding: '1rem 1.25rem', fontSize: '1rem', gap: '1rem' }}
        className='d-flex flex-column'
      >
        <div onClick={() => window.open("https://wa.me/6285891101162","_blank")}>Register</div>
        <div onClick={() => handleClick('/unit-lelang')}>Unit Lelang</div>
        <div>Jadwal</div>
        <div
          onClick={() => handleClick('/jadwal-lelang-hari-ini')}
          style={{ marginLeft: '1.5rem' }}
        >
          Lelang Hari Ini
        </div>
        <div
          onClick={() => handleClick('/jadwal-lelang-mendatang')}
          style={{ marginLeft: '1.5rem' }}
        >
          Lelang Mendatang
        </div>
        <div onClick={() => handleClick('/titip-lelang')}>Titip Lelang</div>
        <div onClick={() => handleClick('/beli-nipl')}>Beli NIPL</div>
      </div>
    </div>
  );
};

export default HeaderMenu;
